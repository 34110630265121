<template>
    <div class="container-fluid">
        <b-row>
            <b-col class="mt-5 text-center">
                <span style="font-size: 24px">ลืมรหัสผ่าน</span>
            </b-col>
        </b-row>
        <br/>
        <b-row>
            <b-col sm="2" md="4" lg="4" xl="4"></b-col>
            <b-col sm="8" md="4" lg="4" xl="4">
                <div class="main-form-oscc pb-5 pr-3 pl-3 pt-2">
                    <b-row>
                        <b-col cols="12" class="body-form-oscc">
                            <b-form-group
                                    label-for="input1"
                                    class="mb-0"
                            >
                                <template #label>
                                    กรุณากรอกอีเมลของคุณเพื่อตั้งรหัสผ่านใหม่
                                </template>
                                <b-form-input
                                        id="input1"
                                        v-model="email"
                                        class="input-form-oscc"
                                        placeholder="ระบุอีเมล"
                                        :state="isEmailValid"
                                        type="email"
                                ></b-form-input>
                                <b-form-invalid-feedback id="input-live-feedback">
                                    ระบุอีเมล
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
            <b-col sm="2" md="4" lg="4" xl="4"></b-col>
        </b-row>
        <br/>
        <b-row>
            <b-col sm="2" md="4" lg="4" xl="4"></b-col>
            <b-col sm="8" md="4" lg="4" xl="4">
                <div>
                    <b-button class="btn-oscc" variant="outline-secondary" @click="showAlert">ยกเลิก</b-button>
                    <b-button class="btn-submit-oscc float-right" variant="primary" @click="sendData">ดำเนินการต่อ</b-button>
                </div>
            </b-col>
            <b-col sm="2" md="4" lg="4" xl="4"></b-col>
        </b-row>
    </div>
</template>

<script>
    export default {
        name: "ForgotPassword",
        data(){
            return{
                //eslint-disable-next-line
                reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
                email:'',
            }
        },
        computed: {
            isEmailValid: function () {
                return this.reg.test(this.email);
            },
        },
        methods:{
            sendData(){
                if (this.email && !this.isEmailValid)
                    this.$swal.fire({
                    icon: 'error',
                    title: 'รูปแบบอีเมลไม่ถูกต้อง',
                })
                else if(!this.email)
                    this.$swal.fire({
                    icon: 'error',
                    title: 'กรุณาระบุอีเมล',
                })
                else{
                    this.showLoading();
                    let data = {
                        'email' : this.email
                    }
                    this.$store.dispatch('Auth/forgotpassword',data).then(res=>{
                        if(res.error){
                            this.$swal.fire({
                                icon: 'error',
                                title: res.error
                            })
                        }else if(res.data){
                            this.$swal.fire({
                                icon: 'success',
                                title: res.data
                            })
                        }
                    })
                }
            },
            showAlert(){
                this.$swal.fire({
                    title: 'ยกเลิกการทำรายการ ?',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'ใช่',
                    cancelButtonText: 'ไม่ใช่'
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$router.push('/')
                    }
                })
            },
            showLoading(){
                this.$loading.show({background:
                        this.$swal.fire({
                            allowEscapeKey: false,
                            allowOutsideClick: false,
                            didOpen: () => {
                                this.$swal.showLoading()
                            }
                        })
                    ,animation : true});
            },
            hideLoading(){
                this.$loading.hide();
                this.$swal.hideLoading()
            },
        }
    }
</script>

<style scoped>
    .main-form-oscc{
        background: #FFFFFF;
        border: 0.5px solid #A8A8A8;
        box-sizing: border-box;
        box-shadow: 1px 1px 9px 1px rgba(174, 194, 217, 0.3);
        border-radius: 5px;
    }
    .body-form-oscc{
        padding-left: 7%;
        padding-right: 6%;
        margin-top: 13px;
    }
    .btn-oscc{
        font-size: 14px;
        box-shadow: 1px 1px 10px rgba(56, 84, 138, 0.28);
        border-radius: 24px;
        padding: 10px 30px 10px 30px;
    }
    .btn-submit-oscc{
        font-size: 14px;
        box-shadow: 1px 1px 10px rgba(56, 84, 138, 0.28);
        border-radius: 24px;
        padding: 10px 70px 10px 70px;
    }
</style>
